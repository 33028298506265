import {getGfFieldId} from '@/functions/wordpress/gravityForms'
import cn from 'classnames'
import styles from './Section.module.scss'
export default function Section({id, label, description, cssClass}) {
  const fieldId = getGfFieldId(id)
  return (
    <div id={fieldId}>
      <div className={styles.label}>{label}</div>
      <div className={cn(styles.description, cssClass)}>{description}</div>
    </div>
  )
}
