import authorPostFields from '@/lib/wordpress/_query-partials/authorPostFields'
import categoriesPostFields from '@/lib/wordpress/_query-partials/categoriesPostFields'
import defaultPageData from '@/lib/wordpress/_query-partials/defaultPageData'
import featuredImagePostFields from '@/lib/wordpress/_query-partials/featuredImagePostFields'
import globalPostFields from '@/lib/wordpress/_query-partials/globalPostFields'
import postACFFields from '@/lib/wordpress/_query-partials/postACFFields'

import seoPostFields from '@/lib/wordpress/_query-partials/seoPostFields'
import tagsPostFields from '@/lib/wordpress/_query-partials/tagsPostFields'
import {gql} from '@apollo/client'
import acfBlocks from '../_query-partials/acfBlocks'

// Fragment: retrieve single post fields.
export const singlePostFragment = gql`
  fragment SinglePostFields on Post {
    ${globalPostFields}
    blocksJSON
    excerpt
    language {
      code
    }
    translations {
      slug
    }
    ${seoPostFields}
    ${authorPostFields}
    ${featuredImagePostFields}
    ${tagsPostFields}
    ${categoriesPostFields}
    ${acfBlocks}
    ${postACFFields}
  }
`
// Query: retrieve post by specified identifier.
const queryPostById = gql`
  query GET_POST_BY_ID(
    $id: ID!
    $idType: PostIdType = SLUG
    $themeOptionLanguage: LanguageCodeFilterEnum
  ) {
    ${defaultPageData}
    post(id: $id, idType: $idType) {
      ...SinglePostFields
      revisions(first: 1, where: {orderby: {field: DATE, order: DESC}}) {
        edges {
          node {
            ${globalPostFields}
            blocksJSON
            excerpt
          }
        }
      }
    }
  }
  ${singlePostFragment}
`

export default queryPostById
