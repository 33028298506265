import cn from 'classnames'
import {useState} from 'react'
import {Collapse} from 'react-collapse'
import styles from './HistoryAccordion.module.scss'

export type HistoryCard = {
  title: string
  subtitle: string
  icon: string
  datecards: [
    {
      date: string
      text: string
    }
  ]
}

type HistoryAccordionProps = {
  card: HistoryCard
}

export default function HistoryAccordion({card}: HistoryAccordionProps) {
  const [show, setShow] = useState(false)

  return (
    <div className={styles.cardWrapper} onClick={() => setShow(!show)}>
      <div className={cn(styles.titleWrapper, show ? styles.isActive : '')}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {card?.icon ? (
              <span className="material-symbols-outlined">{card.icon}</span>
            ) : (
              card.title
            )}
          </div>

          <div className={styles.subtitle}>{card.subtitle}</div>
        </div>
        <div className={styles.icon} />
      </div>
      <div className={styles.dateCardsWrapper}>
        <Collapse isOpened={show}>
          {card?.datecards &&
            card?.datecards?.map((dateCard, index) => (
              <div key={index} className={styles.dateCardWrapper}>
                <div className={styles.date}>{dateCard.date}</div>
                <div
                  className={styles.text}
                  dangerouslySetInnerHTML={{__html: dateCard.text}}
                />
              </div>
            ))}
        </Collapse>
      </div>
    </div>
  )
}
