import {LinkType} from '@/components/types/link'

import Button from '@/components/atoms/Button'

import Label from '@/components/atoms/Label'

import styles from './GreyHeader.module.scss'

type GreyHeaderProps = {
  link: LinkType
  label: string
  heading: string
}

export default function GreyHeader({link, label, heading}: GreyHeaderProps) {
  return (
    <div className={styles.header}>
      <div className={styles.labelWrapper}>
        <Label text={label} />
      </div>
      <h3 className={styles.heading}>{heading}</h3>
      {link?.url && <Button type="primary" text={link.title} url={link.url} />}
    </div>
  )
}
