import InputError from '@/components/atoms/Inputs/InputError'
import stylesText from '@/components/atoms/Inputs/Text/Text.module.scss'
import styles from './File.module.scss'

import Icon from '@/components/atoms/Icon'
import {getGfFieldId} from '@/functions/wordpress/gravityForms'
import cn from 'classnames'
import {Field} from 'formik'
import PropTypes from 'prop-types'
import {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

/**
 * Render GravityForms File field component.
 *
 * @param  {object}        props               GravityForm Select field as props.
 * @param  {string}        props.className     GravityForm field wrapper class.
 * @param  {string}        props.description   GravityForm field description.
 * @param  {string|number} props.id            GravityForm field id.
 * @param  {boolean}       props.isRequired    GravityForm field is required.
 * @param  {string}        props.label         GravityForm field label.
 * @param  {boolean}       props.visibility    GravityForm visibility option.
 * @param  {Function}      props.setFieldValue Formik function to set state.
 * @return {Element}                           The File component.
 */
export default function File({
  className,
  description,
  id,
  isRequired,
  label,
  visibility,
  setFieldValue,
  conditionalLogic,
  values
}) {
  const {t} = useTranslation('programm')
  const fieldId = getGfFieldId(id)
  const thisClassName = cn(className)
  const [fileName, setFileName] = useState('')
  const [showError, setShowError] = useState('')
  const [hidden, setHidden] = useState(
    conditionalLogic && conditionalLogic.rules.length > 0
  )

  useEffect(() => {
    if (conditionalLogic && conditionalLogic.rules.length > 0) {
      const fieldValue = values[`input_${conditionalLogic.rules[0].fieldId}`]
      setHidden(fieldValue !== conditionalLogic.rules[0].value)
    }
  }, [conditionalLogic, values])

  const truncateText = (text) => {
    const middleIndex = Math.floor(text.length / 2)

    if (text.length > 40) {
      const truncatedText =
        text.slice(0, middleIndex - 10) + '...' + text.slice(middleIndex + 10)
      return truncatedText
    }

    return text
  }

  let ref = useRef()
  return (
    <div
      className={cn(
        stylesText.text,
        stylesText.extraPadding,
        showError ? styles.isError : '',
        thisClassName,
        visibility === 'HIDDEN' || hidden ? styles.isHidden : ''
      )}
    >
      <div className={styles.container}>
        {label && (
          <label htmlFor={id}>
            {label} {isRequired && '*'}
          </label>
        )}
        <div
          className={cn(styles.fileWrapper, showError ? styles.hasError : '')}
        >
          <div className={styles.filePlaceholder}>
            {fileName.length === 0 && (
              <Icon
                icon={'plusFile'}
                size={'24'}
                sizeViewbox={'24'}
                title={'plus file'}
              />
            )}
            {fileName.length > 0 ? truncateText(fileName) : t('programm:file')}
          </div>
          {fileName.length > 0 && (
            <Icon
              className={styles.delete}
              icon="delete"
              size={24}
              sizeViewbox={24}
              onClick={() => {
                ref.current.value = ''
                setFileName('')
                setFieldValue(`${fieldId}`, undefined)
              }}
            />
          )}
          <Field
            innerRef={ref}
            aria-required={isRequired && !hidden}
            id={fieldId}
            name={fieldId}
            required={isRequired && !hidden}
            className={styles.file}
            type="file"
            accept="application/pdf"
            onInvalid={() => setShowError(true)}
            value={undefined}
            onChange={(e) => {
              setFileName(e.target.files[0].name)
              setShowError(false)
              // Save to _filedata here so we don't corrupt state.
              setFieldValue(`${fieldId}`, e.target.files[0])
            }}
          />
        </div>
      </div>
      {description && <p>{description}</p>}
      {showError ? (
        <InputError name={t('programm:supportedFiles')} />
      ) : (
        <div className={styles.showLabel}>{t('programm:supportedFiles')}</div>
      )}
    </div>
  )
}

File.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  selectChoices: PropTypes.arrayOf(PropTypes.object),
  visibility: PropTypes.string,
  setFieldValue: PropTypes.func
}
