import {useEffect, useState} from 'react'

import styles from './Lecturers.module.scss'

import Container from '@/components/atoms/Container'

import Button from '@/components/atoms/Button'

import LecturerBox from '@/components/molecules/LecturerBox'

import {LecturerType} from '@/components/types/lecturer'

import useMediaQuery from '@/functions/useMediaQuery'

import {lg} from 'config'

import {useTranslation} from 'next-i18next'

type LecturersProps = {
  block: {
    lecturers: LecturerType[]
  }
}

export default function Lecturers({block}: LecturersProps) {
  const [showMore, setShowMore] = useState(false)

  const {lecturers} = block

  const isLg = useMediaQuery(lg)

  const showNumber = isLg ? 3 : 4

  const {t} = useTranslation('global')

  useEffect(() => {
    setShowMore(lecturers?.length > showNumber)
  }, [lecturers, showNumber])

  return (
    <Container>
      <div className={styles.lecturersWrapper}>
        {lecturers
          ?.slice(0, showMore ? showNumber : lecturers.length)
          ?.map((lecturer: LecturerType, index: number) => (
            <LecturerBox lecturer={lecturer} key={index} />
          ))}
      </div>
      {showMore && (
        <Button
          onClick={() => setShowMore(false)}
          className={styles.buttonWrapper}
          type="primary"
          text={t('showMoreButton')}
        />
      )}
    </Container>
  )
}
