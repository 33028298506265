export enum labelMapping {
  accreditation,
  applicationDeadline,
  classSize,
  diploma,
  language,
  partnerInstitution,
  targetGroup,
  nextCourse,
  duration,
  format,
  participationFee,
  location,
  sponsers,
  mediaSubscriptionFee,
  bookingTimeSlot
}

export const labelMappingCourse = {
  accreditation: 'accreditation',
  applicationDeadline: 'applicationDeadline',
  classSize: 'classSize',
  diploma: 'diploma',
  language: 'language',
  location: 'location',
  partnerInstitution: 'partnerInstitution',
  targetGroup: 'targetGroup',
  nextCourse: 'nextCourse',
  duration: 'duration',
  format: 'format',
  participationFee: 'participationFee',
  sponsers: 'sponsers',
  mediaSubscriptionFee: 'mediaSubscriptionFee',
  bookingTimeSlot: 'bookingTimeSlot'
}

export const labelMappingIconCourse = {
  accreditation: 'workspace_premium',
  applicationDeadline: 'alarm',
  classSize: 'hub',
  diploma: 'school',
  language: 'language',
  location: 'place',
  partnerInstitution: 'handshake',
  targetGroup: 'groups',
  nextCourse: 'calendar_month',
  duration: 'av_timer',
  format: 'co_present',
  participationFee: 'sell',
  sponsers: 'factory',
  mediaSubscriptionFee: 'sell'
}
