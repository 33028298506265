import Button from '@/components/atoms/Button'
import Container from '@/components/atoms/Container'
import GreyHeader from '@/components/atoms/GreyHeader'
import SubtitleWithDot from '@/components/atoms/SubtitleWithDot'

import {LinkType} from '@/components/types/link'
import {Post} from '@/components/types/post'
import {formatDate} from '@/functions/utils'
import Image from 'next/image'
import Link from 'next/link'
import styles from './LatestPosts.module.scss'

type LatestPostsProps = {
  block: {
    label: string
    heading: string
    link: LinkType
    postsLink: LinkType
    posts: Post[]
  }
}

export default function LatestPosts({block}: LatestPostsProps) {
  const {label, heading, link, postsLink, posts} = block
  return (
    <div className={styles.latestPosts}>
      <Container>
        <GreyHeader label={label} heading={heading} link={link} />
        <div className={styles.wrapper}>
          <div className={styles.container}>
            {posts?.map((post, index) => (
              <div className={styles.post} key={index}>
                {post.featuredImage && (
                  <div className={styles.image}>
                    <Image
                      src={post.featuredImage?.node.mediaItemUrl}
                      alt={post.featuredImage?.node.altText}
                      width={post.featuredImage?.node.mediaDetails.width}
                      height={post.featuredImage?.node.mediaDetails.height}
                      layout="responsive"
                      objectFit="cover"
                      priority
                    />
                  </div>
                )}
                <div>
                  <SubtitleWithDot
                    word1={post.categories.nodes[0].name}
                    word2={formatDate(post.date)}
                    className={styles.postSubtitle}
                  />
                  <Link href={`news/${post.slug}`}>
                    <a className={styles.postTitle}>{post.title}</a>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          {postsLink?.url && (
            <Button type="primary" text={postsLink.title} url={postsLink.url} />
          )}
        </div>
      </Container>
    </div>
  )
}
