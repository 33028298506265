import {initializeWpApollo} from '@/lib/wordpress/connector'
import {queryAllPosts} from '@/lib/wordpress/posts/queryAllPosts'

export default async function getAllPosts(variables) {
  const apolloClient = initializeWpApollo()

  const response = {
    data: null,
    error: false,
    errorMessage: null
  }
  response.data = await apolloClient
    .query({
      query: queryAllPosts,
      variables
    })
    .then((res) => {
      const {data} = res
      return data
    })
    .catch((error) => {
      response.error = true
      response.errorMessage = error.message

      return null
    })

  return response
}
