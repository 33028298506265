import Container from '@/components/atoms/Container'
import {ImageType} from '@/components/types/image'
import Image from 'next/image'
import styles from './Partners.module.scss'

type Card = {
  text?: string
  image: ImageType
}

type PartnersProps = {
  block: {
    title: string
    cards: Card[]
  }
}

export default function Partners({block}: PartnersProps) {
  const {title, cards} = block
  return (
    <Container>
      <div className={styles.wrapper}>
        {title?.length > 0 && <h6 className={styles.title}>{title}</h6>}
        <div className={styles.cardsWrapper}>
          {cards?.map((card, index) => (
            <div key={index} className={styles.cardContainer}>
              {card.text && <div className={styles.cardText}>{card.text}</div>}
              <div className={styles.imageWrapper}>
                {card.image && (
                  <div className={styles.image}>
                    <Image
                      src={card.image.mediaItemUrl}
                      alt={card.image.altText}
                      width={card.image.mediaDetails.width}
                      height={card.image.mediaDetails.height}
                      layout="responsive"
                      objectFit="contain"
                      priority
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}
