import Image from 'next/image'

import Button from '../../../atoms/Button/Button'

import styles from './Intro.module.scss'

import Container from '@/components/atoms/Container'

import {LinkType} from '@/components/types/link'

import cn from 'classnames'

import {ImageType} from '@/components/types/image'

type IntroProps = {
  block: {
    title: string
    text: string
    link: LinkType
    image: ImageType
  }
}

export default function Intro({block}: IntroProps) {
  const {title, text, link, image} = block
  return (
    <Container>
      <div className={styles.intro}>
        <h2 className={cn(styles.title, 'intro-title')}>{title}</h2>
        <div className={image ? styles.textImageWrapper : ''}>
          <div className={styles.textWrapper}>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{__html: text}}
            />
            {link && (
              <Button
                type="primary"
                url={link.url}
                text={link.title}
                attributes={{target: link.target}}
              />
            )}
          </div>
          {image?.mediaItemUrl && (
            <div className={styles.image}>
              <Image
                src={image.mediaItemUrl}
                alt={image.altText}
                width={image.mediaDetails.width}
                height={image.mediaDetails.height}
                layout="responsive"
                priority
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}
