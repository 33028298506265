import cn from 'classnames'

import styles from './Section.module.scss'

import Button from '@/components/atoms/Button'

import Container from '@/components/atoms/Container'

import Accordion from '@/components/atoms/Accordion'

import Label from '@/components/atoms/Label'

import LinkWithArrow from '@/components/atoms/LinkWithArrow'

import {GravityFormContext} from '@/components/molecules/GravityForm/GravityFormContext'

import {LinkType} from '@/components/types/link'

import {useContext} from 'react'

import HistoryAccordion, {
  HistoryCard
} from '@/components/atoms/HistoryAccordion/HistoryAccordion'

import Video from '@/components/atoms/Video'
import {enableScroll} from '@/functions/enableDisableScroll'

type SectionProps = {
  block: {
    title: string
    text: string
    video: {
      mediaItemUrl: string
    }
    videoText: string
    label: string
    size: string
    link: LinkType
    contactFormButton: string
    links: [{link: LinkType}]
    titleLink: LinkType
    accordions: [
      {
        title: string
        text: string
        link: LinkType
        isTimeTable: boolean
      }
    ]
    cards: HistoryCard[]
  }
}

export default function Section({block}: SectionProps) {
  const {
    title,
    titleLink,
    text,
    label,
    size,
    link,
    links,
    accordions,
    contactFormButton,
    cards,
    video,
    videoText
  } = block
  const {setIsFormOpen} = useContext(GravityFormContext)

  return (
    <Container
      constantPaddingBottom={size === 'md'}
      changePaddingBottom={size === 'sm'}
    >
      <div className={styles.section}>
        <Label text={label} />
        <div
          className={cn(
            styles.wrapper,
            size === 'sm' ? styles.isRow : styles.isColumn
          )}
        >
          <div className={styles.title}>
            <div>{title}</div>
            {titleLink && (
              <Button
                className={styles.titleLink}
                type="primary"
                url={titleLink.url}
                text={titleLink.title}
                urlExternal={titleLink.target === '_blank'}
              />
            )}
          </div>
          <div className={styles.variationWrapper}>
            {video && (
              <div className={styles.videoWrapper}>
                <Video video={video} videoText={videoText} />{' '}
              </div>
            )}
            {text && (
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{__html: text}}
              />
            )}

            {contactFormButton ? (
              <Button
                className={styles.button}
                type="primary"
                text={contactFormButton}
                onClick={() => {
                  enableScroll()
                  setIsFormOpen && setIsFormOpen(true)
                }}
              />
            ) : links && links.length > 0 ? (
              <div className={styles.linkList}>
                {links?.map(
                  (element, index: number) =>
                    element.link && (
                      <LinkWithArrow key={index} link={element.link} />
                    )
                )}
              </div>
            ) : link ? (
              <Button
                className={styles.button}
                type="primary"
                url={link.url}
                text={link.title}
              />
            ) : accordions && accordions.length > 0 ? (
              <div className={styles.accordions}>
                {accordions?.map((accordion, index) => (
                  <Accordion key={index} {...accordion} />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {cards && cards.length > 0 && (
        <div className={styles.historyWrapper}>
          {cards.map((card, index) => (
            <HistoryAccordion key={index} card={card} />
          ))}
        </div>
      )}
    </Container>
  )
}
