export const dateFormat = (stringDate) => {
  const date = new Date(stringDate)
  const yyyy = date.getFullYear()
  let mm = date.getMonth() + 1 // Months start at 0!
  let dd = date.getDate()

  if (dd < 10) dd = '0' + dd
  if (mm < 10) mm = '0' + mm

  return dd + '.' + mm + '.' + yyyy
}

export const changeWordpressDateFormat = (stringDate) => {
  if (!stringDate) return
  const [day, month, year] = stringDate.split('/')
  return new Date(year, month - 1, day)
}
