import {LinkType} from '@/components/types/link'

import Link from 'next/link'

import Icon from '../Icon'

import styles from './LinkWithArrow.module.scss'

type LinkWithArrowProps = {
  link: LinkType
}

export default function LinkWithArrow({link}: LinkWithArrowProps) {
  return (
    <Link href={link.url}>
      <div className={styles.link}>
        <a>{link.title}</a>
        <Icon icon="arrowRight" size={32} sizeViewbox={32} />
      </div>
    </Link>
  )
}
