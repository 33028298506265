import Button from '@/components/atoms/Button'

import Container from '@/components/atoms/Container'

import ProgramsTable from '@/components/atoms/ProgramsTable'

import {useWordPressContext} from '@/components/common/WordPressProvider'

import {formatCourse} from '@/functions/formatCourses'

import {formatProgram} from '@/functions/formatPrograms'

import {useTranslation} from 'next-i18next'

import {useState} from 'react'

import styles from './CourseDates.module.scss'

interface CourseDatesProps {
  block: {
    title: string
    text?: string
  }
}

export default function CourseDates({block}: CourseDatesProps) {
  const {post}: any = useWordPressContext()
  const {title, text} = block

  const {t} = useTranslation('global')

  const formattedPost =
    post.__typename === 'Course' ? formatCourse(post) : formatProgram(post)

  const [showMore, setShowMore] = useState(
    formattedPost.variations?.nodes?.length > 2 ||
      formattedPost?.variations?.length > 2
  )

  return (
    <div id="courseDates">
      <Container>
        {title?.length > 0 && <div className={styles.title}>{title}</div>}
        {text && text?.length > 0 && (
          <div className={styles.textWrapper}>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{__html: text}}
            />
          </div>
        )}
        <div className={styles.table}>
          <ProgramsTable
            hasVariations
            courses={
              post.__typename === 'Course'
                ? formattedPost?.variations
                : formattedPost
            }
            showMore={showMore}
            defaultShown={2}
          />
        </div>
        {showMore && (
          <Button
            className={styles.button}
            onClick={() => setShowMore(false)}
            type="primary"
            text={t('showMoreButton')}
          />
        )}
      </Container>
    </div>
  )
}
