import * as Input from '@/components/atoms/Inputs'
import {useWordPressContext} from '@/components/common/WordPressProvider'
import {
  getGfFieldId,
  getGfHiddenClassName
} from '@/functions/wordpress/gravityForms'
import cn from 'classnames'
import styles from './Consent.module.scss'

/**
 * Render GravityForms Textarea field component.
 *
 * @param  {object}        props             GravityForm Select field as props.
 * @param  {string}        props.className   GravityForm field wrapper class.
 * @param  {string}        props.description GravityForm field description.
 * @param  {string|number} props.id          GravityForm field id.
 * @param  {boolean}       props.isRequired  GravityForm field is required.
 * @param  {string}        props.label       GravityForm field label.
 * @param  {boolean}       props.visibility  GravityForm visibility option.
 * @return {Element}                         The File component.
 */
export default function Consent({
  className,
  description,
  id,
  isRequired,
  visibility,
  setFieldValue
}) {
  const fieldId = getGfFieldId(id)
  const isHiddenClass = getGfHiddenClassName(visibility)
  const thisClassName = cn(className, isHiddenClass)
  const {
    themeOptions: {consent}
  } = useWordPressContext()
  return (
    <div className={cn(thisClassName, styles.consent)}>
      <Input.Checkbox
        className={cn(className, isHiddenClass) || null}
        description={description}
        id={fieldId}
        label={consent}
        isRequired={isRequired}
        setFieldValue={setFieldValue}
      />
    </div>
  )
}
