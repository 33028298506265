import Icon from '@/components/atoms/Icon'
import cn from 'classnames'
import {useState} from 'react'
import styles from './Search.module.scss'
type SearchProps = {
  searchValue: string
  setSearchValue: (value: string) => void
}

export default function Search({searchValue, setSearchValue}: SearchProps) {
  const [focus, setFocus] = useState(false)

  return (
    <div className={cn(styles.inputWrapper, focus ? styles.isFocus : '')}>
      <input
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        type="text"
        placeholder="Suchen"
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
      <div className={styles.iconWrapper}>
        {searchValue.length > 0 && (
          <Icon
            className={styles.delete}
            icon="delete"
            size={24}
            sizeViewbox={24}
            onClick={() => setSearchValue('')}
          />
        )}
        <Icon
          className={styles.search}
          icon="search"
          size={24}
          sizeViewbox={24}
        />
      </div>
    </div>
  )
}
