import Image from 'next/image'

import styles from './Quote.module.scss'

import Container from '@/components/atoms/Container'

import {ImageType} from '@/components/types/image'

type QuoteProps = {
  block: {
    quote: string
    name: string
    role: string
    location: string
    image: ImageType
  }
}

export default function Quote({block}: QuoteProps) {
  const {quote, name, role, image, location} = block
  return (
    <div className={styles.quoteWrapper}>
      <Container>
        <div className={styles.quoteContainer}>
          <div className={styles.textWrapper}>
            <div>
              <div className={styles.quote}>{quote}”</div>
              <div className={styles.name}>
                {name}
                <div>{role}</div>
                <div>{location}</div>
              </div>
            </div>
          </div>
          {image && (
            <div className={styles.imageWrapper}>
              <Image
                src={image.mediaItemUrl}
                alt={image.altText}
                width={image.mediaDetails.width}
                height={image.mediaDetails.height}
                layout="responsive"
                priority
              />
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}
