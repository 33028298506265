import Image from 'next/image'

import styles from './LecturerBox.module.scss'

import {LecturerType} from '@/components/types/lecturer'
import Link from 'next/link'

type Props = {
  lecturer: LecturerType
  style?: any
}
export default function LecturerBox({lecturer, style}: Props) {
  return (
    <div className={styles.imageTextWrapper} style={style}>
      {lecturer.featuredImage && lecturer.featuredImage.node && (
        <div className={styles.image}>
          <Image
            src={lecturer.featuredImage.node.mediaItemUrl}
            alt={lecturer.featuredImage.node.altText}
            width={lecturer.featuredImage.node.mediaDetails.width}
            height={lecturer.featuredImage.node.mediaDetails.height}
            layout="responsive"
            priority
          />
        </div>
      )}
      <div className={styles.textWrapper}>
        <div className={styles.name}>{lecturer.title}</div>
        <div className={styles.role}>{lecturer.lecturer.role}</div>
        <div className={styles.role}>{lecturer.lecturer.location}</div>
        {(lecturer.lecturer.telephone || lecturer.lecturer.email) && (
          <div className={styles.telephoneEmailWrapper}>
            {lecturer.lecturer.telephone && (
              <div className={styles.telephone}>
                {lecturer.lecturer.telephone}
              </div>
            )}
            {lecturer.lecturer.email && (
              <div className={styles.email}>
                <Link href={`mailto:${lecturer.lecturer.email}`}>
                  {lecturer.lecturer.email}
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
