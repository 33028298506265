// Query partial: retrieve SEO post fields.
const acfImage = `
  image {
    altText
    mediaItemUrl
    mediaDetails {
      width
      height
    }
  }
`

export default acfImage
