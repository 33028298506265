import Container from '@/components/atoms/Container'
import Label from '@/components/atoms/Label'
import LecturerBox from '@/components/molecules/LecturerBox'
import {LecturerType} from '@/components/types/lecturer'
import Image from 'next/image'
import Link from 'next/link'
import styles from './CourseContactPerson.module.scss'

type CourseContactPersonProps = {
  block: {
    label: string
    title: string
    team: LecturerType[]
  }
}

const CourseContactPerson = ({block}: CourseContactPersonProps) => {
  const {label, title, team} = block

  return (
    <Container>
      <div className={styles.courseContactPerson}>
        <Label text={label} />
        <div className={styles.title}>{title}</div>

        {team && team.length > 1 ? (
          <div className={styles.lecturersWrapper}>
            {team?.map((lecturer, index) => (
              <LecturerBox lecturer={lecturer} key={index} />
            ))}
          </div>
        ) : (
          team &&
          team.map((lecturerItem, index) => {
            const {title: lecturerName, lecturer, featuredImage} = lecturerItem

            return (
              <div key={index} className={styles.imageTextWrapper}>
                {featuredImage?.node && (
                  <a className={styles.image}>
                    <Image
                      src={featuredImage.node.mediaItemUrl}
                      alt={featuredImage.node.altText}
                      width={featuredImage.node.mediaDetails.width}
                      height={featuredImage.node.mediaDetails.height}
                      layout="responsive"
                      priority
                    />
                  </a>
                )}
                <div className={styles.textWrapper}>
                  <div className={styles.name}>{lecturerName}</div>
                  <div className={styles.role}>
                    <div>{lecturer.role}</div>
                    <div>{lecturer.location}</div>
                  </div>
                  <div className={styles.telephone}>{lecturer.telephone}</div>
                  <div className={styles.email}>
                    <Link href={`mailto:${lecturer.email}`}>
                      {lecturer.email}
                    </Link>
                  </div>
                </div>
              </div>
            )
          })
        )}
      </div>
    </Container>
  )
}

export default CourseContactPerson
