import {gql} from '@apollo/client'
import {numberOfPostsPerPage} from '../connector'
import {singlePostFragment} from './queryPostById'

export const queryAllPosts = gql`
  query GET_ALL_POSTS(
    $after: String
    $before: String
    $first: Int = ${numberOfPostsPerPage}
    $last: Int
    $categoryName: String
    $search: String
    $year: Int
    $excludeBySlug: ID = null
  ) {
    categories {
      nodes {
        name
        slug
        count
      }
    }
    posts(
      after: $after
      before: $before
      last: $last
      first: $first
      where: {
        dateQuery: {year: $year}
        categoryName: $categoryName
        orderby: {field: DATE, order: DESC}
        search: $search
        excludeBySlug: $excludeBySlug
      }
    ) {
      nodes {
        ...SinglePostFields
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
    allPosts: posts(where: {
       dateQuery: {year: $year}
        categoryName: $categoryName
        orderby: {field: DATE, order: DESC}
        search: $search
    }) {
      nodes {
        date
      }
      pageInfo {
        total
      }
    }
  }
  ${singlePostFragment}
`
