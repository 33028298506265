const themeOptions = `
themeOptions(language: $themeOptionLanguage) {
  themeOptions {
    address
    email
    emailInTheFooter
    telephone
    cookieBannerDescription
    acceptLabel
    rejectLabel
    cookiesLabelSettings
    dataProtectionPageLink {
      title
      url
    }
    cookies {
      title
      description
      cookieName
      cookies {
        name
        holder
        domain
        description
        expires
      }
    } 
    consent
    paymentConsent
  }
}
`
export default themeOptions
