import allMenus from './allMenus'
import defaultSeoFields from './defaultSeoFields'
import themeOptions from './themeOptions'
// Query partial: retrieve default data for all frontend pages.
const defaultPageData = `
  ${defaultSeoFields}
  ${allMenus}
  ${themeOptions}

`

export default defaultPageData
