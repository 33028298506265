// Query partial: retrieve SEO post fields.
import acfAnchor from './acfAnchor'
import acfImage from './acfImage'
import acfVideo from './acfVideo'
import featuredImagePostFields from './featuredImagePostFields'

const blocks = `
    ... on AcfTeaserBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      Teaser {
        title
        subtitle
        subtitle2
        ${acfVideo}
        isBlog
      }
    }
    
    ... on AcfVideoBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      Video {
        ${acfVideo}
        videoText
      }
    }
    ... on AcfIntroBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      Intro {
        ${acfImage}
        link {
          title
          url
          target
        }
        text
        title
      }
    }
    ... on AcfSectionBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      Section {
        ${acfVideo}
        videoText
        titleLink {
          title
          url
          target
        }
        link {
          title
          url
          target
        }
        links {
          link {
            title
            url
            target
          }
        }
        size
        title
        text
        label
        contactFormButton
        accordions {
          title
          text
          isTimeTable
          link {
            title
            url
            target
          }
        }
        cards {
          title
          subtitle
          icon
          datecards {
            date
            text
          }
        }
      }
    }
    ... on AcfFullWidthImageBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      FullWidthImage {
        ${acfImage}
        imageMobile {
          altText
          mediaItemUrl
          mediaDetails {
            width
            height
          }
        }
      }
    }
    ... on AcfCourseContactPersonBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      CourseContactPerson {
        title
        label
        team {
          __typename
          ... on Team {
            title
            uri
            ${featuredImagePostFields}
            lecturer {
              email
              role
              location
              telephone
            }
          }
        }
      }
    }
    ... on AcfLecturersBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      Lecturers {
        lecturers {
          __typename
          ... on Lecturer {
            title
            uri
           ${featuredImagePostFields}
            lecturer {
              role
              location
            }
          }
        }
      }
    }

    ... on AcfCourseDetailsBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      CourseDetails {
        title
        text
        label
        link
      }
    }

    ... on AcfCourseDatesBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      CourseDates {
        title
        text
      }
    }

    ... on AcfQuoteBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      Quote {
        ${acfImage}
        quote
        name
        role
        location
      }
    }


    ... on AcfNumbersBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      Numbers {
        title
        cards {
          label
          icon
          title
          count
        }
      }
    }

    ... on AcfContactsBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      Contacts {
        title
        contacts {
          ... on Lecturer {
            title
            uri
           ${featuredImagePostFields}
            lecturer {
              role
              location
              email
              telephone
            }
          }
          ... on Team {
            title
            uri
           ${featuredImagePostFields}
            lecturer {
              role
              location
              email
              telephone
            }
          }
        }
      }
    }

    ... on AcfLocationsBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      Locations {
        locations {
          title
          subtitle
          text
          url
          ${acfImage}
        }
      }
    }

    ... on AcfImagesBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      Images {
        variation
        images {
          ${acfImage}
        }
      }
    }

    ... on AcfPrinciplesBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      Principles {
        principles {
          title
          text
          icon
        }
      }
    }

    ... on AcfAllLecturersBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      AllLecturers {
       label
      }
    }

    ... on AcfAllTeamBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      AllTeam {
       label
      }
    }

    ... on AcfAllPostsBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      AllPosts {
       title
       subtitle
      }
    }

    ... on AcfLatestPostsBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      LatestPosts {
       label
       heading
       link {
        title
        url
        target
       }
       postsLink {
        title
        url
        target
       }
      }
    }

    ... on AcfLinkListBlock {
      attributesJSON
      dynamicContent
      ${acfAnchor}
      LinkList {
        title
        items {
          link {
            title
            target
            url
          }
          subtitle
          subtitle2
        }
      }
    }

    ... on AcfTeaserCardsBlock {
      attributesJSON
      dynamicContent
      TeaserCards {
        cards {
          link {
            title
            target
            url
          }
          title
          subtitle
          ${acfImage}
        }
      }
    }

    ... on AcfPartnersBlock {
      attributesJSON
      dynamicContent
      Partners {
        title
        cards {
          text
          ${acfImage}
        }
      }
    }

        ... on AcfBannerBlock {
      attributesJSON
      dynamicContent
      Banner {
        title
        subtitle
      }
    }
`

const acfBlocks = `
  blocks {
    ... on CoreBlock {
      innerBlocks {
       ${blocks}
      }
    }
   ${blocks}
}

`

export default acfBlocks
