import Container from '@/components/atoms/Container'
import styles from './Numbers.module.scss'

type Card = {
  count: string
  label?: string
  title?: string
  icon?: string
}

type NumbersProps = {
  block: {
    title: string
    cards: Card[]
  }
}

export default function Numbers({block}: NumbersProps) {
  const {title, cards} = block
  return (
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.countsWrapper}>
          {cards.map((card: Card, index: number) => (
            <div key={index} className={styles.countWrapper}>
              <div key={index} className={styles.countContainer}>
                {card?.icon ? (
                  <div className={styles.icon}>
                    <span className="material-symbols-outlined">
                      {card.icon}
                    </span>
                  </div>
                ) : (
                  <div className={styles.count}>{card.count}</div>
                )}
                {card.title && (
                  <div className={styles.headline}>{card.title}</div>
                )}
                <div className={styles.label}>{card.label}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}
