// Query partial: retrieve global post fields.
const globalPostFields = `
  databaseId
  date
  slug
  uri
  title
  status
`
export default globalPostFields
