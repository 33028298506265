import cn from 'classnames'

import styles from './Tabs.module.scss'

export default function Tabs({currentState, setCurrentState, data}) {
  return (
    <div className={styles.categoriesChoicesWrapper}>
      <div
        onClick={() => setCurrentState(0)}
        className={styles.categoryChoiceWrapper}
      >
        <div
          className={cn(
            styles.categoryChoice,
            currentState === 0 ? styles.isSelected : ''
          )}
        >
          Alle
        </div>
      </div>
      {data?.map((category, index) => (
        <div
          key={index + 1}
          onClick={() => setCurrentState(index + 1)}
          className={styles.categoryChoiceWrapper}
        >
          <div
            key={index + 1}
            className={cn(
              styles.categoryChoice,
              index + 1 === currentState ? styles.isSelected : ''
            )}
          >
            {category.name}
          </div>
        </div>
      ))}
    </div>
  )
}
