import Container from '@/components/atoms/Container'

import Label from '@/components/atoms/Label'

import LecturerBox from '@/components/molecules/LecturerBox'

import Tabs from '@/components/molecules/Tabs'

import Search from '@/components/molecules/Search'

import {LecturerCategorysType, LecturerType} from '@/components/types/lecturer'

import {animated, useTransition} from '@react-spring/web'

import {useEffect, useState} from 'react'

import styles from './AllLecturers.module.scss'

type Props = {
  block: {
    type: string
    label: string
    lecturers: LecturerCategorysType[]
  }
}
export default function AllLecturers({block}: Props) {
  const {lecturers: lecturerCategorys, type, label} = block
  const [searchValue, setSearchValue] = useState('')
  const [show, setShow] = useState(false)

  const [visibleLecturer, setVisibleLecturer] = useState<
    (LecturerType | undefined)[]
  >([])
  const [activeCategory, setActiveCategory] = useState(0)

  const categories = lecturerCategorys?.filter((category) => category.count)

  const filterLecturers = () => {
    let lecturers = []
    if (activeCategory === 0) {
      categories.forEach((category: LecturerCategorysType) => {
        lecturers.push(
          type == 'team' ? category.teams?.nodes : category.lecturers?.nodes
        )
      })
    } else {
      if (type == 'team') {
        lecturers.push(categories[activeCategory - 1].teams?.nodes)
      } else {
        lecturers.push(categories[activeCategory - 1].lecturers?.nodes)
      }
    }
    return lecturers.flat()
  }

  useEffect(() => {
    setShow(false)
    if (activeCategory !== 0) setSearchValue('')
    setTimeout(() => {
      setVisibleLecturer(filterLecturers())
      setShow(true)
    }, 1000)
  }, [activeCategory])

  useEffect(() => {
    let lecturers = filterLecturers()

    if (searchValue.length > 0) {
      setActiveCategory(0)
      lecturers = lecturers.filter((value) =>
        value?.title.toLowerCase().includes(searchValue.toLowerCase())
      )
    }
    setVisibleLecturer(lecturers)
  }, [searchValue])

  const itemsTransitions = useTransition(show ? visibleLecturer : [], {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
    config: {duration: 500}
  })

  const AnimatedLecturerBox = animated(LecturerBox)

  return (
    <div className={styles.courses}>
      <Container>
        <div className={styles.labelWrapper}>
          <Label text={label} />
        </div>
        <Search searchValue={searchValue} setSearchValue={setSearchValue} />
        <div className={styles.tabsHeadline}>Personen filtern nach:</div>
        <Tabs
          currentState={activeCategory}
          setCurrentState={setActiveCategory}
          data={categories}
        />
        <div className={styles.lecturersWrapper}>
          {itemsTransitions(
            (style, item) =>
              item && <AnimatedLecturerBox style={style} lecturer={item} />
          )}
        </div>
      </Container>
    </div>
  )
}
