import {useContext} from 'react'

import Button from '@/components/atoms/Button'

import Container from '@/components/atoms/Container'

import Label from '@/components/atoms/Label'

import {useWordPressContext} from '@/components/common/WordPressProvider'

import styles from './CourseDetails.module.scss'

import {useTranslation} from 'next-i18next'

import {GravityFormContext} from '@/components/molecules/GravityForm/GravityFormContext'

import {enableScroll} from '@/functions/enableDisableScroll'

import {
  labelMapping,
  labelMappingCourse,
  labelMappingIconCourse
} from '@/components/types/labelMapping'

type CourseDetailsProps = {
  block: {
    title: string
    text: string
    label: string
    link: string
  }
}

export default function CourseDetails({block}: CourseDetailsProps) {
  const {title, text, label, link} = block

  const {post}: any = useWordPressContext()

  const {setIsFormOpen} = useContext(GravityFormContext)

  const {t} = useTranslation(['global', 'labelMapping', 'programm'])
  return (
    <Container>
      <div id="courseDetails" className={styles.courseDetails}>
        <Label text={label} />
        <div className={styles.title}>{title}</div>

        <div className={styles.row}>
          <div className={styles.informationWrapper}>
            <div className={styles.informationBox}>
              <div>
                {Object.keys(labelMapping).map(function (key) {
                  const label = t(`labelMapping:${key}`)
                  const formattedPostKey =
                    post.__typename === 'Course'
                      ? post?.CourseFields[
                          labelMappingCourse[
                            key as keyof typeof labelMappingCourse
                          ]
                        ]
                      : post?.ProgramFields[key]
                  return (
                    formattedPostKey?.length > 0 &&
                    label && (
                      <div key={key} className={styles.informationContainer}>
                        <span className="material-symbols-outlined">
                          {
                            labelMappingIconCourse[
                              key as keyof typeof labelMappingIconCourse
                            ]
                          }
                        </span>
                        <div>
                          <div className={styles.label}>{label}</div>
                          <div
                            className={styles.field}
                            dangerouslySetInnerHTML={{__html: formattedPostKey}}
                          ></div>
                        </div>
                      </div>
                    )
                  )
                })}
              </div>
              {post.__typename === 'Course' && (
                <Button
                  isSmall
                  className={styles.button}
                  text={
                    post?.CourseFields?.buttonText ||
                    t('programm:applyNowButton')
                  }
                  type="filled"
                  icon={null}
                  url={post?.CourseFields?.url}
                  urlExternal={
                    post?.CourseFields?.url &&
                    post?.CourseFields?.url?.length > 0
                  }
                  onClick={
                    post?.CourseFields?.url &&
                    post?.CourseFields?.url?.length > 0
                      ? () => false
                      : () => {
                          enableScroll()
                          setIsFormOpen && setIsFormOpen(true)
                        }
                  }
                />
              )}
            </div>
            {post.__typename === 'VariableProduct' && (
              <Button
                className={styles.appointmentsButton}
                text={
                  post?.ProgramFields?.buttonText
                    ? post?.ProgramFields?.buttonText
                    : t('programm:allApointments')
                }
                type="primary"
                isSmall
                icon={'arrowRightOutline'}
                isAnchor
                url="#courseDates"
              />
            )}
          </div>
          <div className={styles.textWrapper}>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{__html: text}}
            />
            {link && (
              <Button
                urlExternal
                url={link}
                className={styles.link}
                text={t('global:requestInformationLabel')}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  )
}
