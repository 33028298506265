import Container from '@/components/atoms/Container'

import Image from 'next/image'

import {ImageType} from '@/components/types/image'

import {LinkType} from '@/components/types/link'

import Button from '@/components/atoms/Button'

import Icon from '@/components/atoms/Icon'
import {md, xxl} from '@/config'
import useMediaQuery from '@/functions/useMediaQuery'
import cn from 'classnames'
import {MouseEventHandler} from 'react'
import Slider from 'react-slick'
import styles from './TeaserCards.module.scss'

type Card = {
  subtitle: string
  title: string
  image: ImageType
  link: LinkType
}

type ArrowProps = {
  className?: string
  onClick?: MouseEventHandler<any>
}

type TeaserCardsProps = {
  block: {
    cards: Card[]
  }
}
function Arrow(props: ArrowProps) {
  const {className, onClick} = props
  return (
    <div onClick={onClick} className={className}>
      <div className={styles.circle}>
        <Icon icon="arrowRightOutline" size={40} sizeViewbox={40} />
      </div>
    </div>
  )
}

export default function TeaserCards({block}: TeaserCardsProps) {
  const isMd = useMediaQuery(md)
  const isXxl = useMediaQuery(xxl)
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: (isXxl && block?.cards?.length > 4) || !isXxl ? true : false,
    draggable: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }

  const {cards} = block

  function renderCard(card: Card, index: number) {
    return (
      <div key={index} className={styles.cardContainer}>
        {card.image && (
          <div className={styles.cardImage}>
            <Image
              src={card.image.mediaItemUrl}
              alt={card.image.altText}
              width={card.image.mediaDetails.width}
              height={card.image.mediaDetails.height}
              layout="responsive"
              objectFit="cover"
              priority
            />
          </div>
        )}
        <div className={styles.textContainer}>
          {card.title && isMd ? (
            <div className={styles.title}>{card.title}</div>
          ) : (
            <Button
              className={styles.title}
              isAnchor={card.link.url.startsWith('#')}
              url={card.link.url}
              text={card.title}
              icon={null}
            />
          )}
          {card.subtitle && (
            <div className={styles.subtitle}>{card.subtitle}</div>
          )}
          {card.link && isMd && (
            <div className={styles.linkWrapper}>
              <Button
                isAnchor={card.link.url.startsWith('#')}
                url={card.link.url}
                text={card.link.title}
                icon={null}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <Container>
      <div
        className={cn(
          styles.wrapper,
          (isXxl && block?.cards?.length > 4) || !isXxl
            ? styles.hasExtraMargin
            : ''
        )}
      >
        {isMd ? (
          <div className={styles.slider}>
            <Slider {...sliderSettings}>
              {cards.map((card: Card, index: number) =>
                renderCard(card, index)
              )}
            </Slider>
          </div>
        ) : (
          cards.map((card: Card, index: number) => renderCard(card, index))
        )}
      </div>
    </Container>
  )
}
