import Image from 'next/image'

import {md} from 'config'

import Container from '@/components/atoms/Container'
import useMediaQuery from '@/functions/useMediaQuery'

export default function FullWidthImage({block}: any) {
  const {image, imageMobile} = block
  const isMd = useMediaQuery(md)

  return (
    <Container>
      {isMd && image ? (
        <Image
          src={image.mediaItemUrl}
          alt={image.altText}
          width={image.mediaDetails.width}
          height={image.mediaDetails.height}
          layout="responsive"
          priority
        />
      ) : (
        imageMobile && (
          <Image
            src={imageMobile.mediaItemUrl}
            alt={imageMobile.altText}
            width={imageMobile.mediaDetails.width}
            height={imageMobile.mediaDetails.height}
            layout="responsive"
            priority
          />
        )
      )}
    </Container>
  )
}
