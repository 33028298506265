export const formatProgram = (course) => {
  const clonedVariations = {...course}
  const variation = course.translation?.variations?.nodes
  delete clonedVariations.variations
  const clonedNodes = course.variations?.nodes.map((node, index) => {
    const clonedAttributes = {...node.attributes}
    const clonedNodesArray = [...clonedAttributes.nodes]

    clonedNodesArray[0] = {
      ...clonedNodesArray[0],
      name: clonedNodesArray[0].value
    }

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    let offerFrom,
      offerTo,
      note = null
    let applyOffer = false
    const variationDescription = variation[index].description
    const currentMetaData = variation[index].metaData
    if (currentMetaData)
      for (let i = 0; i < currentMetaData?.length; i++) {
        if (currentMetaData[i].key === '_offer_from') {
          offerFrom = new Date(currentMetaData[i].value)
          offerFrom.setHours(0, 0, 0, 0)
        } else if (currentMetaData[i].key === '_offer_to') {
          offerTo = new Date(currentMetaData[i].value)
          offerTo.setHours(0, 0, 0, 0)
        } else if (currentMetaData[i].key === '_note') {
          note = currentMetaData[i].value
        }
      }

    if (offerFrom <= today && today <= offerTo) {
      applyOffer = true
    }

    const variationObject = {}

    for (const attribute of node.attributes.nodes) {
      variationObject[attribute.name] = attribute.valueName
        ? attribute.valueName
        : attribute.value
    }

    return {
      ...clonedVariations,
      ...variationObject,
      variationId: node.databaseId,
      variationPrice: node.price,
      variationOfferNotAvailable:
        (offerFrom && offerTo && !applyOffer) || false,
      variationNote: note,
      variationDescription,
      variationStockQuantity:
        course.ProgramFields?.disabled || (offerFrom && offerTo && !applyOffer)
          ? null
          : node.stockQuantity
    }
  })

  return clonedNodes
}

export const formatPrograms = (courses) =>
  courses.map((course) => formatProgram(course))
