import Container from '@/components/atoms/Container'
import {ImageType} from '@/components/types/image'
import cn from 'classnames'
import Image from 'next/image'
import styles from './Images.module.scss'

type ImageProps = {
  block: {
    variation: string
    images: [{image: ImageType}, {image: ImageType}, {image: ImageType}]
  }
}

export default function Images({block}: ImageProps) {
  const {images, variation} = block
  return (
    <Container>
      <div className={cn(styles.wrapper, 'block-images')}>
        {images?.map(
          (image, index) =>
            image?.image && (
              <div key={index} className={cn(styles.image, styles[variation])}>
                <Image
                  src={image.image.mediaItemUrl}
                  alt={image.image.altText}
                  width={image.image.mediaDetails.width}
                  height={image.image.mediaDetails.height}
                  layout="responsive"
                  priority
                />
              </div>
            )
        )}
      </div>
    </Container>
  )
}
