import useOutsideAlerter from '@/components/atoms/OutsideAlerter/OutsideAlerter'
import {useWordPressContext} from '@/components/common/WordPressProvider'
import Form from '@/components/molecules/Form'
import getGfFormDefaults from '@/functions/wordpress/gravityForms/getGfFormDefaults'
import getGfFormValidationSchema from '@/functions/wordpress/gravityForms/getGfFormValidationSchema'
import cn from 'classnames'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {wpApiUrlBase} from '../../../lib/wordpress/connector'
import Button from '../../atoms/Button/Button'
import Fields from './Fields'
import styles from './GravityForm.module.scss'
import {GravityFormContext} from './GravityFormContext'

/**
 * Render the GravityForm component.
 *
 * @param  {object}  props                      The GravityForm block attributes as props.
 * @param  {object}  props.formData             GravityForm form data.
 * @param  {string}  props.formData.cssClass    GravityForm form classname.
 * @param  {string}  props.formData.description GravityForm form description.
 * @param  {object}  props.formData.formFields  GravityForm form fields.
 * @param  {number}  props.formData.formId      GravityForm form id.
 * @param  {string}  props.formData.title       GravityForm form title.
 * @return {Element}                            The GravityForm component.
 */
export default function GravityForm({formData}) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const {cssClass, description, formFields, formId, title} = formData
  const {isFormOpen, setIsFormOpen} = useContext(GravityFormContext)
  const {t} = useTranslation('global')
  const fieldData = formFields?.edges
  const formValidationSchema = getGfFormValidationSchema(fieldData)
  const fieldDefaults = getGfFormDefaults(fieldData)
  const [isInitialRender, setIsIntialRender] = useState(false)

  const [isFadeOut, setIsFadeOut] = useState(false)
  const [formFeedback, setFeedback] = useState(false)

  const ref = useRef(null)

  useOutsideAlerter({
    ref,
    isOpen: isFormOpen,
    setIsOpen: setIsFormOpen,
    isOverlay: true
  })

  const {post} = useWordPressContext()

  /**
   * Handle form submission.
   *
   * @author WebDevStudios
   * @param {object} values Form values.
   */

  async function handleFormSubmission(values) {
    setIsSubmitted(true)
    let courseName = ''
    if (title !== 'Kontakt' && title !== 'Contact') {
      courseName = formFields.edges.find(
        (field) => field?.node?.inputName === 'Course Name'
      )

      values[`input_${courseName?.node?.id}`] = post.title
    }

    const formData = new FormData()
    for (const key in values) {
      const value = values[key]

      if (value === false || value === true) {
        formData.append(
          `${key}.1`,
          value === false ? '0' : value === true ? '1' : value
        )
      } else {
        formData.append(key, value)
      }
    }
    formData.append('form_id', formId)

    const response = await fetch(
      `${wpApiUrlBase?.slice(0, -1)}-json/custom-api/v1/submit-form`,
      {
        method: 'POST',
        body: formData
      }
    )

    const data = await response.json()

    setIsFadeOut(true)

    setTimeout(() => {
      if (!data?.entry_id) {
        setFeedback('Error')
        return
      }

      if (window !== undefined) {
        window.dataLayer?.push({
          event: 'form_submit',
          form_id: formId,
          form_title: title
        })
      }

      setFeedback(data?.entry_id?.confirmation_message)
    }, 1000)
  }

  const handleCloseForm = () => {
    setIsFormOpen(false)
    setTimeout(() => {
      setIsSubmitted(false)
      setFeedback('')
      setIsFadeOut(false)
    }, 1000)
  }

  useEffect(() => {
    if (isFormOpen) {
      setIsIntialRender(true)
    }
  }, [isFormOpen])

  return (
    <div
      ref={ref}
      className={cn(styles.gravityForm, isFormOpen ? styles.isActive : '')}
    >
      <Form
        className={cn(
          cssClass,
          !isInitialRender ? '' : isFormOpen ? 'is--active' : 'is--inactive'
        )}
        formDefaults={fieldDefaults}
        id={formId && `gform-${formId}`}
        validationSchema={formValidationSchema}
        onSubmit={handleFormSubmission}
        onClose={handleCloseForm}
        registrieren
      >
        {(formikProps) =>
          formFeedback ? (
            <div className={styles.fadeIn}>
              <div
                className={styles.feedback}
                dangerouslySetInnerHTML={{__html: formFeedback}}
              />
              <Button
                onClick={() => handleCloseForm()}
                type="filled"
                text={t('global:closeForm')}
                icon={null}
              />
            </div>
          ) : (
            <div
              className={cn(
                isFadeOut ? styles.fadeOut : '',
                styles.formContainer
              )}
            >
              {title !== 'Kontakt' && title !== 'Contact' ? (
                <>
                  <h2 className={styles.title}>
                    {t('global:programFormTitleLabel')}
                  </h2>
                  <div className={styles.subtitleLabel}>
                    {t('global:programFormLabel')}
                  </div>
                  <div className={styles.subtitle}>{post.title}</div>
                </>
              ) : (
                <h2 className={styles.title}>{t('global:contactFormLabel')}</h2>
              )}
              {description && <p>{description}</p>}
              {fieldData && (
                <Fields fields={fieldData} formikProps={formikProps} />
              )}
              <div
                className={cn(
                  styles.buttonWrapper,
                  isSubmitted ? styles.isLoading : ''
                )}
              >
                <Button
                  tag="button"
                  iconSize={24}
                  icon="arrowForward"
                  type="filled"
                  text={t('global:submitForm')}
                  isLoading={isSubmitted}
                />
              </div>
            </div>
          )
        }
      </Form>
    </div>
  )
}

GravityForm.propTypes = {
  formData: PropTypes.shape({
    cssClass: PropTypes.string,
    description: PropTypes.string,
    formFields: PropTypes.object,
    formId: PropTypes.number,
    title: PropTypes.string
  })
}
