import Container from '@/components/atoms/Container'

import styles from './Banner.module.scss'

interface BannerProps {
  block: {
    title: string
    subtitle?: string
  }
}

export default function Banner({block}: BannerProps) {
  const {title, subtitle} = block

  return (
    <Container>
      <div className={styles.banner}>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        {title && <div className={styles.title}>{title}</div>}
      </div>
    </Container>
  )
}
