// Query partial: retrieve all menus.
const allMenus = `
  menus {
    nodes {
      locations
      menuItems(first: 1000) {
        nodes {
          id
          parentId
          label
          path
          target
          title
          cssClasses
          MenuACF {
           isSocial 
          }
          legalMenu {
            isCookies
          }
        }
      }
    }
  }
`

export default allMenus
