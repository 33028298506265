import styles from './Contacts.module.scss'

import Container from '@/components/atoms/Container'

import LecturerBox from '@/components/molecules/LecturerBox'
import {LecturerType} from '@/components/types/lecturer'

type ContactsProps = {
  block: {
    title: string
    contacts: LecturerType[]
  }
}

export default function Contacts({block}: ContactsProps) {
  const {title, contacts} = block
  return (
    <div className={styles.contacts}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.title}>{title}</div>
          <div className={styles.contactsWrapper}>
            {contacts?.map((lecturer: LecturerType, index: number) => (
              <LecturerBox lecturer={lecturer} key={index} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}
