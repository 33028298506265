import Container from '@/components/atoms/Container'
import styles from './Principles.module.scss'

type PrinciplesProps = {
  block: {
    principles: [{title: string; text: string; icon: string}]
  }
}
export default function Principles({block}: PrinciplesProps) {
  const {principles} = block
  return (
    <Container>
      <div className={styles.wrapper}>
        {principles.map((principle, index) => (
          <div className={styles.principle} key={index}>
            <div className={styles.icon}>
              <span className="material-symbols-outlined">
                {principle.icon}
              </span>
            </div>
            <div className={styles.textContainer}>
              <div className={styles.title}>{principle.title}</div>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{__html: principle.text}}
              />
            </div>
          </div>
        ))}
      </div>
    </Container>
  )
}
