import PropTypes from 'prop-types'

import displayBlock from '@/functions/wordpress/blocks/displayBlock'

import {useWordPressContext} from '@/components/common/WordPressProvider'

/**
 * Render the Blocks component.
 *
 * @author WebDevStudios
 * @param  {object}  props        The component attributes as props.
 * @param  {Array}   props.blocks The array of blocks.
 * @return {Element}              The Blocks component.
 */
export default function Blocks({blocks}) {
  const {post} = useWordPressContext()

  return (
    <>
      {
        // If there are blocks, loop over and display.
        !!blocks?.length &&
          blocks.map((block, index) => {
            return block.innerBlocks.length > 0 ? (
              <Blocks key={index} blocks={block.innerBlocks} />
            ) : (
              <div
                key={index}
                id={block?.attributes?.block?.anchor || ''}
                className={
                  post.__typename === 'Post' && block.name.includes('acf/')
                    ? 'is--full-width'
                    : ''
                }
              >
                {displayBlock(block, index)}
              </div>
            )
          })
      }
    </>
  )
}

Blocks.propTypes = {
  blocks: PropTypes.array.isRequired
}

Blocks.defaultProps = {
  blocks: []
}
