import styles from './Locations.module.scss'

import Container from '@/components/atoms/Container'

import Button from '@/components/atoms/Button'

import {ImageType} from '@/components/types/image'
import Image from 'next/image'

type LocationsProps = {
  block: {
    locations: [
      {
        title: string
        subtitle: string
        text: string
        url: string
        image: ImageType
      }
    ]
  }
}

export default function Locations({block}: LocationsProps) {
  const {locations} = block
  return (
    <Container>
      <div className={styles.locationsWrapper}>
        {locations?.map((location, index: number) => (
          <div key={index} className={styles.location}>
            <div key={index} className={styles.locationContainer}>
              <div className={styles.image}>
                <Image
                  src={location.image.mediaItemUrl}
                  alt={location.image.altText}
                  width={location.image.mediaDetails.width}
                  height={location.image.mediaDetails.height}
                  layout="responsive"
                  priority
                />
              </div>
              <div className={styles.title}>{location.title}</div>
              <div className={styles.subtitle}>{location.subtitle}</div>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{__html: location.text}}
              />
              <Button
                urlExternal
                url={location.url}
                type="primary"
                text="Google Maps"
              />
            </div>
          </div>
        ))}
      </div>
    </Container>
  )
}
